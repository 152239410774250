(function(window) {

/**
 * Budskap is an error/done message handler.
 *
 * Works with Laravel error responses.
 */
var Budskap = function(element) {
    var make = function(content, style) {
        if (typeof content === 'object') {
            // Validation error
            if (content.status === 422) {
                if (typeof content.responseJSON !== 'undefined') {
                    content = content.responseJSON;
                }

                // Grab first error
                var keys = Object.keys(content);
                content = content[keys[0]];
            } else {
                content = "Something went terribly wrong!";
            }
        }

        if (typeof content !== 'undefined') {
            $element.html(content);
        }

        $element
            .removeClass('green red')
            .addClass(style)
            .show();
    };

    var $element = element instanceof jQuery
        ? element : $(element);

    return {
        done: function(content) {
            make(content, 'green');
        },
        fail: function(content) {
            make(content, 'red');
        },
        make: make
    };
};

window.Budskap = Budskap;

})(window);
