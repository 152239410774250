(function(window) {

/**
 * Cookie handler script.
 *
 * Use single values:
 * Cookie.set('foo', 'bar', 5);
 * Cookie.get('foo');
 *
 * Use array values:
 * Cookie.setArray('foo', [1, 2, 3, 4]);
 * Cookie.getArray('foo');
 *
 * Erase cookie:
 * Cookie.erase('foo');
 */
var Cookie = function() {

    function _set(key, value, days, path, domain, secure) {
        days = days || 365;
        var now = new Date(),
            expires = '; expires=' + (new Date(now.getTime() + (1000 * 60 * 60 * 24 * days))).toGMTString(),
            max_age = '; max-age=' + 60 * 60 * 24 * days;

        document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) +
                          expires + max_age +
                          (domain ? '; domain=' + domain : '') +
                          (path ? '; path=' + path : '; path=/') +
                          (secure ? '; secure' : '');
        return true;
    }

    function _get(key) {
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" +
               encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") +
               "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    }

    function _erase(key, path, domain) {
        if (!key || !_get(key)) return false;
        document.cookie = encodeURIComponent(key) + '=' +
                         '; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
                         (domain ? '; domain=' + domain : '') +
                         (path ? '; path=' + path : '; path=/');
        return true;
    }

    function _setArray(key, value, days, path, domain, secure) {
        return _set(key, JSON.stringify(value), days, path, domain, secure);
    }

    function _getArray(key) {
        return $.parseJSON(_get(key));
    }

    return {
        set: _set,
        get: _get,
        erase: _erase,
        setArray: _setArray,
        getArray: _getArray,
        addToArray: function(key, value, max) {
            if ( _get( key ) ) {
                var array_data = _getArray(key);
                // Only add to array if the key does not previously exist.
                if (array_data.indexOf(value) === -1) {
                    array_data.unshift(value);
                    if (max) array_data = array_data.splice(0, max);
                    _setArray(key, array_data);
                }
                return array_data.length;
            } else {
                _setArray(key, [value]);
                return 1;
            }
        },
        eraseFromArray: function(key, value) {
            if ( _get( key ) ) {
                var array_data = _getArray(key),
                    index = array_data.indexOf(value);
                array_data.splice(index, 1);
                _setArray(key, array_data);
                return array_data.length;
            }
        }
    };
}();

window.Cookie = Cookie;

})(window);
