(function(window, $) {

/**
 * Cookie handler script.
 */
var Modal = function(on_close_callback) {
    var $modal = $('.modal'),
        $wrapper = $('.modal-wrapper'),
        $bg = $modal.prev(),
        $canvas = $('html, body');

    $modal.on('click', _close_event);
    $modal.on('click', '.modal-close', _close_event);
    $(window.document).on('keyup', _close_event);

    function _close_modal() {
        $canvas.removeClass('modal-open');
        $wrapper.html('');

        _clean_additional_wrapper_classes();

        if (typeof on_close_callback === 'function') {
            on_close_callback();
        }
    }

    function _close_event(event) {
        if ((event.type === 'click' && event.target !== this) ||
            (event.type === 'keyup' && event.keyCode !== 27)) return;

        event.preventDefault();

        _close_modal();
    }

    function _clean_additional_wrapper_classes() {
        $wrapper.removeClass(function(index, value) {
            return (value.match(/(^|\s)modal\-wrapper\-\S+/g) || []).join(' ');
        });
    }

    function _show_modal() {
        $canvas.addClass('modal-open');
    }

    function _prepare_wrapper(additional_classes) {
        $wrapper.addClass(additional_classes);
    }

    function show(additional_classes) {
        _prepare_wrapper(additional_classes);
        _show_modal();

        return this;
    }

    return {
        /**
         */
        show: show,

        /**
         */
        showAsync: function(done, additional_classes) {
            var $spinner = $modal.children('.spinner');

            _show_modal();
            $modal.addClass('modal-loading');

            return function() {
                _prepare_wrapper(additional_classes);
                $modal.removeClass('modal-loading');
                done.apply(this, arguments);
            }
        },

        /**
         */
        html: function(content) {
            $wrapper.html(content);
        },

        /**
         */
        close: _close_modal,

        _$wrapper: $wrapper
    };
};

window.Modal = Modal;

})(window, jQuery);
