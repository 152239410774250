(function(window, $) {

/**
 * Rating component
 *
 * Only behaves as listening to objects created. So pass a common
 * query string for the component.
 */
var Rating = function(element_query) {

    function rateShop(event) {
        event.preventDefault();

        var $this = $(this).parent(),
            $wrapper = $this.closest('.rating-wrapper'),
            shop_id = $wrapper.data('id'),
            what = $wrapper.data('fm-rate');

        var data = {
            stars: $this.data('stars'),
            _token: $wrapper.data('token')
        };

        what = typeof what !== 'undefined' && what.length ? what : 'shops';

        $.post('/api/' + what + '/' + shop_id + '/rating', data)
            .done(function(response) {
                var stars = Math.round(response.score),
                    $p = $this.parent().next();

                // Text
                $p.html(response.message);
                setTimeout(function() {
                    $p.fadeOut(100, function() {
                        $p.html(response.content).fadeIn(100);
                    });
                }, 1500);

                // Stars
                $this.parent().children()
                    .removeClass('active')
                    .each(function(index, elem) {
                        if (stars <= index) return;
                        $(elem).addClass('active');
                    });
            });
    }

    function rateShopHover(event) {
        event.preventDefault();

        $(this).parent().addClass('hover')
            .prevAll().addClass('hover');
    }

    function rateShopLeave(event) {
        event.preventDefault();

        $(this).parent().removeClass('hover')
            .siblings().removeClass('hover');
    }

    $(window.document).on('click', element_query + ' .rating-stars a', rateShop);
    $(window.document).on('mouseenter', element_query + ' .rating-stars a', rateShopHover);
    $(window.document).on('mouseleave', element_query + ' .rating-stars a', rateShopLeave);
};

window.Rating = Rating;

})(window, jQuery);
