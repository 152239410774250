(function (window) {

    /*!
     * Main Javascript for Footmall.se <Footmall Norden AB>
     * @author Alexander Cederblad <alexceder.se>
     */
    var Footmall = function ($, Budskap, Cookie, Handlebars, Modal, SlideMenu) {
        var arr = [];
        var gender = '';
        var category = '';
        if(typeof size_arr != 'undefined') {
            arr = size_arr.length > 0 ? size_arr : [];
        }

        if(typeof gen != 'undefined') {
            gender = gen;
        }

        if (typeof cat != 'undefined') {
            category = cat;
        }
        /*jshint validthis:true */
        'use strict';

        var filter = {
            gender: gender,
            children: '',
            cat: category,
            brand: '',
            priceMin: '',
            priceMax: '',
            color: '',
            size: arr,
            sale: '',
            sort: '',
            search: '',
            perPage: '',
            shop: []
        },

            pathname_filter = {},

            cache = {
                brands: [],
                cats: []
            },

            page = {
                num: 1,
                perPage: 60,
                width: null,
                xhr: null,
                mainSlideMenu: null,
                sidebarSlideMenu: null,
                newsletterInteractions: 0,
                newsletterInteractionsMax: 5
            },

            mediaWidth = {
                mobile: 830,
                active: null
            },

            widget = {
                breadcrumb: $('.breadcrumb-title'),
                fav_banner: $('.banner-icon-fav'),
                latest_banner: $('.banner-icon-eye'),
                up: $('.up-btn')
            },

            template = {
                // Precompilation will be tough with translations.
                items: hbs('items')
            },

            _filter_name = {},

            iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

        /**
         * Handlebars helper.
         */
        Handlebars.registerHelper('str_limit', function (str) {
            return new Handlebars.SafeString(str_limit(str));
        });

        Handlebars.registerHelper('format_stars', function (stars) {
            var str = '',
                round = Math.round(stars);

            for (var i = 1; i <= 5; ++i) {
                if (i <= round) {
                    str += '<li data-stars="' + i + '" class="active"><a href="#"></a></li>';
                } else {
                    str += '<li data-stars="' + i + '"><a href="#"></a></li>';
                }
            }

            return new Handlebars.SafeString(str);
        });

        // helper
        function str_limit(str, len, end) {
            var len = len || 20,
                end = end || '..';

            if (str && str.length > len + end.length) {
                str = str.substr(0, len) + end;
            }

            return str;
        }

        // helper
        function hbsc(str) {
            return Handlebars.compile(str);
        }

        // helper
        function hbs(template) {
            var $template = $('#template-' + template);
            return $template.length ? hbsc($template.html()) : function () { };
        }

        // helper
        function in_path(needle) {
            if (typeof needle === 'string') needle = [needle];
            var pattern = new RegExp(needle.join('|'));
            return pattern.test(window.location.pathname);
        }

        // helper
        function escape_regex(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        }

        function bootstrap() {
            // Init nprogress.js
            $(document).ajaxStart(NProgress.start);
            $(document).ajaxStop(NProgress.done);

            // Modal -- this is ugly!
            Modal = Modal(function () {
                if (window.location.hash.substr(2, 4) === 'item') {
                    window.location.hash = hash();
                    maybeShowNewsletterModal();
                } else if (window.location.hash.substr(2, 11) === 'influencers') {
                    window.location.hash = hash();
                }
            });

            $('.slide-menu-input').on('focus', function (event) {
                event.preventDefault();

                var $this = $(this);

                $this.blur();
                page.mainSlideMenu.close();
                $('.main-search').focus();
            });

            // Setup the search widget.
            widget.search = $('[data-fm-id=main-search]');
            widget.mainSearch = $('.filter-main-search');

            page.mainSlideMenu = SlideMenu('.main-slide-menu', function (data) {
                if (widget.filter) {
                    clearFilter();
                    filter = $.extend(filter, data);
                    setupFilter();
                    getContent();
                    page.mainSlideMenu.close();
                } else {
                    filter = $.extend(filter, data);
                    window.location = '//' + window.location.host + '/' + hash();
                }
            });

            widget.rowItems = $('.row-items');

            var $filter = $('#filter-container');
            if ($filter[0]) {
                widget.filter = $filter;

                // Setup the objects for each widget in the filter.
                widget.gender = $('.filter-gender');
                widget.children = $('.filter-children');
                widget.color = $('.filter-color');
                widget.size = $('.filter-size');
                widget.cat = $('.filter-cat');
                widget.shop = $('.filter-shop');
                widget.sale = $('.filter-sale');
                widget.brand = {
                    input: $('[data-fm-id="filter-brand-input"]'),
                    list: $('.filter-brand'),
                };

                widget.brand.wrapper = widget.brand.list.parent().parent();

                _filter_name['brand'] = widget.brand.list.closest('.filter-widget').children('h3').html();
                _filter_name['cat'] = widget.cat.closest('.filter-widget').children('h3').html();
                //_filter_name['search'] = 'Fritext';

                widget.perPage = $('.filter-per-page');
                widget.sort = $('.filter-sort');

                widget.filterToggle = $('.filter-toggle');

                widget.filter.addClass('filter-container-ready');

                // "3" because of "#/whatever/u16Vus"
                if ((window.location.hash && window.location.hash.split('/').length > 3) || widget.search.val().length > 0) {
                    if (hashToFilterBinding()) {
                        setupFilter();
                        getContent(true);
                    }
                } else {
                    pathnameToFilterBinding(window.location.pathname, filter);

                    (function () {
                        $.each(['gender', 'cat'], function (index, type) {
                            if (filter[type].length) {
                                var $h3 = widget[type].closest('.filter-widget').children('h3'),
                                    $active = widget[type].children('.active');

                                if ($active[0]) {
                                    $h3.append(cftb(filter[type], type, str_limit($active.children('a').html(), 10)));
                                }
                            }
                        });

                        // Scroll to common size if not set.
                        var scroll_to = widget.size.data('default');

                        // Has to to this because of default size may not exist.
                        widget.size.children().each(function (index, el) {
                            var $li = $(el);

                            if ($li.data('id') > scroll_to) {
                                widget.size.scrollTop($li.position().top + widget.size.scrollTop() - 10);
                                return false; // break
                            }
                        });
                    })();
                }

                getFilterData();
                addFilterEventListeners();

                var _slider = Slider();
                widget.price._slider = _slider;
            } else if (in_path('favourites')) {
                widget.favs = $('.row-items');
                $('.btn-share-favs').on('click', storeFavsCallback);
                $('.btn-clear-favs').on('click', clearFavsCallback);
                $('body').on('click', '.btn-update-favs', updateFavsCallback);
            }

            (function () {
                var hash = window.location.hash,
                    id;

                if (hash && hash.search(/^#\/item\/\d+/) !== -1) {
                    id = hash.split('/');
                    showItemModal(id[2]);
                }

                if (hash && hash.search(/^#\/influencers\/.+/) !== -1) {
                    id = hash.split('/');
                    influencers.clickCallback(id[2]);
                }

                
            })();

            page.sidebarSlideMenu = SlideMenu('.sidebar-slide-menu');
            widget.rowItems.on('click', '.filter-toggle', page.sidebarSlideMenu.show);

            // Add window scroll and resize event.
            var $window = $(window);
            $window.on('scroll', scrollCallback);
            $window.on('resize', resizeCallback);
            $('.send-to-anchor').on('click', sendToAnchor);

            // ================================================
            // Here come the things that need to be listened to
            // every page load, well.. almost every.

            // Items heading text toggle
            $('body').on('click', '.items-heading-text-toggle', function (event) {
                event.preventDefault();

                var $toggle = $(this),
                    val = $toggle.text(),
                    opposite = $toggle.data('opposite');

                $toggle.text(opposite)
                    .data('opposite', val)
                    .prev()
                    .toggleClass('items-heading-text-hide');
            });

            // Main search.
            widget.search.on('keyup', searchMainCallback);

            // Main search iOS fixed/absolute fix
            if (iOS) {
                widget.search.on('focus', function (e, doneOnce) {
                    $(this).closest('header').addClass('input-ios-fixed');
                });

                widget.search.on('blur', function (e) {
                    $(this).closest('header').removeClass('input-ios-fixed');
                });
            }

            // Feed item listeners.
            $(document).on('click', '[data-fm-id="item-show"]', showItemModal);
            $(document).on('click', '[data-fm-id="item-fav"]', favItem);

            // Outbound links tracking
            $(document).on('click', '[data-fm-outbound]', outboundLinkCallback);

            // Rating listeners.
            Rating('.rating-wrapper');

            // Up button listener.
            widget.up.on('click', sendToTopCallback);

            // Campaign banners
            (function () {
                var $banners = $('.banner-campaign');

                if ($banners.length) {
                    var $dismiss_btns = $banners.find('[data-id=dismiss]');

                    $dismiss_btns.on('click', function (event) {
                        event.preventDefault();

                        var $this = $(this),
                            $banner = $this.closest('.banner-campaign'),
                            campaign = $this.data('campaign');

                        $banner.slideUp(function () {
                            $banner.css({ padding: 0, height: 0, border: 0 }); // why?
                            Cookie.set('campaign-banner:' + campaign, true, 7);
                        });
                    });
                }
            })();

            // Sick features
            priceAlertSetup();
            brandWatchSetup();
            newsletterSetup();
            influencers.init();

            resizeCallback();
        }

        function maybeShowNewsletterModal(delay) {
            if (!Cookie.get('newsletter-email') && page.newsletterInteractions > page.newsletterInteractionsMax) {
                setTimeout(function () {
                    Modal.show('modal-wrapper-small').html(hbs('newsletter-modal'));
                    page.newsletterInteractionsMax *= 2;
                }, delay);
            }
        }

        var influencers = {
            $influencer: null,

            init: function () {
                this.$influencer = $('.influencer');

                if (this.$influencer.get(0)) {
                    this.update();
                }

                $(document).on('click', '.o-insta-card__influencer a', this.clickCallback);
            },

            update: function () {
                var height;

                if (this.$influencer && this.$influencer.get(0)) {
                    height = this.resolveHeight();
                    //this.$influencer.css('height', height);
                }
            },

            resolveHeight: function () {
                var next = this.$influencer.next().get(0),
                    rect,
                    height;

                if (next) {
                    rect = next.getBoundingClientRect();

                    if (rect) {
                        height = rect.height || this.$influencer.next().height();

                        return height;
                    }
                }

                return null;
            },

            clickCallback: function (event) {
                var shortcode,
                    source,
                    $item,
                    tmpl = hbs('modal-influencer');

                Modal.close();

                if (typeof event === 'object') {
                    event.preventDefault();

                    $item = $(this).closest('.o-insta-card__influencer');
                    shortcode = $item.data('shortcode');


                    // IE SUX.
                    // If anyone know why this needs to exist and this has no
                    // parentNode, please tell me why that is, if you know.
                    if (!shortcode) shortcode = $this.data('shortcode');
                } else {
                    shortcode = event;
                }
                    

                ga('send', 'event', 'influencers', 'show', filter.brand.length ? filter.brand : undefined);

                $.getJSON('/api/influencers/' + shortcode)
                    .done(Modal.showAsync(function (content) {
                        Modal.html(tmpl(content));
                    }, 'modal-wrapper-item'))
                    .fail(Modal.showAsync(function () {
                        Modal.html('<div class="center">' +
                            '<h1>Something went terribly wrong!</h1>' +
                            '<img src="/gfx/404.png">' +
                            '<p>Please try clicking on another item!</p>' +
                            '</div>');
                    }, 'modal-wrapper-small'))
                    .always(function () {
                        window.location.hash = '#/influencers/' + shortcode;
                    });
            }
        };

        function outboundLinkCallback(event) {
            var data_arr = $(this).data('fm-outbound').split(':'),
                aux;

            if (data_arr.length > 1) {
                aux = data_arr[1];
            } else {
                aux = filter.brand.length ? filter.brand : undefined;
            }

            ga('send', 'event', 'outbound', data_arr[0], aux);
        }

        function priceAlertSetup() {
            $(document).on('click', '[data-fm-id="modal-watch-item"]', function (event) {
                event.preventDefault();

                var $this = $(this),
                    id = $this.closest('.item').data('id') || $this.closest('.item-meta').data('id'),
                    data = {
                        id: id
                    },
                    tmpl;

                $.getJSON('/api/items/' + id)
                    .done(Modal.showAsync(function (content) {
                        tmpl = hbs('modal-watch-item');
                        Modal.html(tmpl(content));
                        ga('send', 'event', 'price_alert', 'show');
                    }, 'modal-wrapper-small'))
                    .fail(Modal.showAsync(function () {
                        Modal.html('<div class="center">' +
                            '<h1>This shoe could not be found</h1>' +
                            '<img src="/gfx/404.png">' +
                            '<p>Please try clicking on another item!</p>' +
                            '</div>');
                    }, 'modal-wrapper-small'));
            });

            $(document.body).on('submit', '[data-fm-id=modal-watch-item-form]', postSignupTo('alerts'));
        }

        function brandWatchSetup() {
            $(document).on('click', '[data-fm-id="modal-watch-brand"]', function (event) {
                event.preventDefault();

                var data = {
                    slug: $(this).data('slug'),
                    name: $(this).data('name')
                },
                    tmpl = hbs('modal-watch-brand');

                ga('send', 'event', 'watch_brand', 'show');

                Modal.show('modal-wrapper-small').html(tmpl(data));
            });

            $(document.body).on('submit', '[data-fm-id=modal-watch-brand-form]', postSignupTo('watch'));
        }

        function postSignupTo(to) {
            return function (event) {
                event.preventDefault();

                var $this = $(this),
                    $inputs = $this.find('input'),
                    data = {},
                    $message = $this.find('.message');

                $inputs.each(function (i, input) {
                    if (input.getAttribute('type') !== 'submit') {
                        data[input.getAttribute('name')] = input.value;
                    }
                });

                if (/^.+@.+\..+$/.test(data.email)) {
                    $.post('/api/' + to, data, null, 'json')
                        .done(function (message) {
                            Budskap($message).make(message.content, message.success ? 'green' : 'red');

                            if (to === 'watch') {
                                ga('send', 'event', 'watch_brand', 'signup');
                            } else if (to === 'alerts') {
                                ga('send', 'event', 'price_alert', 'signup');
                            }
                        })
                        .fail(Budskap($message).fail);
                } else {
                    Budskap($message).fail();
                }
            }
        }

        function newsletterSetup() {
            var shouldShowNewsletterModal = !Cookie.get('popup-newsletter') &&
                document.documentElement.clientWidth > mediaWidth.mobile &&
                $(document.body).data('newsletter-modal');

            if (shouldShowNewsletterModal) {
                $(document).one('scroll', function () {
                    setTimeout(function () {
                        Modal.show('modal-wrapper-small')
                            .html(hbs('newsletter-modal'));

                        ga('send', 'event', 'newsletter', 'show modal', 'load');

                        Cookie.set('popup-newsletter', true, 3);
                    }, 3000);
                });
            }

            $(document).on('submit', '.newsletter-form', function (event) {
                event.preventDefault();

                var $form = $(this),
                    isModal = $form.parent().parent().hasClass('modal-wrapper'),
                    $message = $form.parent().parent().parent().find('.message'),
                    data = {
                        _token: $form.find('input[name=_token]').val(),
                        source: $form.find('input[name=source]').val(),
                        service: $form.find('input[name=service]').val(),
                        email: $form.find('input[name=email]').val()
                    };

                if (/^.+@.+\..+$/.test(data.email)) {
                    $.post('/api/newsletter', data)
                        .done(function (message) {
                            Budskap($message).make(message.content, message.success ? 'green' : 'red');
                            ga('send', 'event', 'newsletter', 'signup', data.source, page.newsletterInteractions);

                            if (isModal) {
                                setTimeout(Modal.close, 3000);
                            } else if ($form.data('hide-on-submit')) {
                                setTimeout(function () {
                                    $form.closest('[data-fm-id=hide-on-submit]').slideUp();
                                }, 2000);
                            }
                        })
                        .fail(Budskap($message).fail)
                        .always(function () {
                            Cookie.set('newsletter-email', data.email);
                        });
                } else {
                    Budskap($message).fail();
                }
            });

            $(document).on('click', '[data-fm-id=modal-newsletter]', function (event) {
                event.preventDefault();
                Modal.show('modal-wrapper-small')
                    .html(hbs('newsletter-modal'));
            });
        };

        function pathnameToFilterBinding(pathname, filter) {
            var pn = pathname.substr(1).split('/'),
                key,
                value,
                ok;

            if (pn.length) {
                ok = ['brand', 'cat', 'gender', 'sale'];
                key = pn[0];

                if (pn.length > 1) {
                    if (key.substr(-1) === 's') {
                        key = key.substr(0, key.length - 1);
                    }

                    value = pn[1];
                } else {
                    value = key;
                }

                // kind of an assert
                if (ok.indexOf(key) !== -1) {
                    pathname_filter[key] = value;
                    filter[key] = value;
                }
            }
        }

        function resizeCallback() {
            var width = document.documentElement.clientWidth,
                shoes,
                $sidebar_main = $('.sidebar-slide-menu');

            influencers.update();

            // If we are at a smaller size than the mobile and mobile is not active
            // go ahead and change a few things.
            if (width < mediaWidth.mobile && mediaWidth.active !== 'mobile') {
                $('.header-logo').prependTo($('.banner-info'));

                // TODO: This needs to be done each time getContent is run.
                $('.items-heading-text').addClass('items-heading-text-hide');

                $('.info-nav-toggle').on('click', function (event) {
                    event.preventDefault();
                    page.mainSlideMenu.show();
                });

                $('.info-nav-toggle').show();

                if ($sidebar_main[0]) {
                    $sidebar_main.addClass('slide-menu');
                }

                mediaWidth.active = 'mobile';
            }

            // And this is the vice versa.
            // Currently only working for these two size
            // if more cases are needed - refactor.
            else if (width > mediaWidth.mobile && mediaWidth.active === 'mobile') {
                $('.header-logo').prependTo($('.banner-main .wrapper-row'));

                $('.info-nav').show();

                $('.info-nav-toggle').off('click');
                $('.items-heading-text-toggle').off('click');

                if ($sidebar_main[0]) {
                    $sidebar_main.removeClass('slide-menu');
                }

                mediaWidth.active = 'desktop';
            }
        }

        function updateFavsCallback(event) {
            event.preventDefault();

            var $form = $(this).closest('form'),
                $message = $form.parent().find('.message'),
                action = $form.attr('action').split('/'),
                id = action[action.length - 1];

            $.ajax({
                url: '/api/favourites/' + id,
                method: 'PATCH',
                data: {
                    _token: $form.find('[name=_token]').val(),
                    title: $form.find('[name=title]').val()
                }
            })
                .done(function (res) {
                    Budskap($message).done(res.content);
                    ga('send', 'event', 'favourite', 'update');
                })
                .fail(Budskap($message).fail);
        }

        function storeFavsCallback(event) {
            event.preventDefault();

            var favs = Cookie.getArray('favs');

            //if (!favs || !favs.length) return;

            $.post('/api/favourites', { _token: $(this).data('token') })
                .done(Modal.showAsync(function (content) {
                    var tmpl = hbs('share-favs');
                    Modal.html(tmpl(content));

                    ga('send', 'event', 'favourite', 'store');
                }, 'modal-wrapper-small'))
                .fail(Modal.showAsync(function (e) {
                    Modal.html(e.responseJSON.content);
                }, 'modal-wrapper-small'));
        }

        function clearFavsCallback(event) {
            event.preventDefault();

            widget.favs.html(hbs('no-favs'));

            // TODO
            // Probably reconsider this to a function that removes an id from favs or all if no parameter.
            // It should also erase the cookie.
            Cookie.erase('favs');
            widget.fav_banner.removeClass('active').html(0);

            ga('send', 'event', 'favourite', 'clear');
        }

        var Slider = function () {
            var slider = document.getElementById('slider'),
                display = slider.previousElementSibling,
                step = parseInt(slider.getAttribute('data-slider-step')),
                price_tmpl,
                settings;

            page.PRICE_MAX = parseInt(slider.getAttribute('data-slider-max'));

            filter.priceMin = filter.priceMin || 0;
            filter.priceMax = filter.priceMax || page.PRICE_MAX;

            settings = {
                start: [filter.priceMin, filter.priceMax],
                range: {
                    min: 0,
                    max: page.PRICE_MAX
                },
                connect: true
            };

            noUiSlider.create(slider, settings);
            _add_event_listeners();
            _setup_filter_tag();

            widget.price = {
                slider: slider,
                display: display
            };

            function _setup_filter_tag() {
                var $tag,
                    $h3;

                if (!(filter.priceMin === 0 && filter.priceMax === page.PRICE_MAX)) {
                    $h3 = $(slider).closest('.filter-widget').children('h3');
                    //$h3.removeClass('filter-show');

                    $h3.append(cftb('', 'price', display.innerHTML));
                }
            }

            function _add_event_listeners() {
                slider.noUiSlider.on('update', function (values, handle) {
                    var vals = [Math.floor(parseInt(values[0])), Math.floor(parseInt(values[1]))],
                        max,
                        min;

                    if (vals[0] !== settings.range.min || vals[1] !== settings.range.max) {
                        filter.priceMin = vals[0];
                        filter.priceMax = vals[1];
                    } else {
                        filter.priceMin = 0;
                        filter.priceMax = page.PRICE_MAX;
                    }

                    max = vals[1] >= page.PRICE_MAX ? page.PRICE_MAX + '+' : vals[1];
                    min = vals[0];

                    price_tmpl = price_tmpl || hbsc(display.getAttribute('data-template').replace(/<\/?\w+>/gm, ''));
                    display.innerHTML = price_tmpl({ min: min, max: max });
                });

                //widget.price.slider.noUiSlider.trigger('change');
                slider.noUiSlider.on('change', function (values, handle) {
                    _setup_filter_tag();

                    sendToTop();
                    getContent();
                });
            }

            return {
                rebuild: function (range) {
                    var start = slider.noUiSlider.get(),
                        vals = [Math.floor(parseInt(start[0])), Math.floor(parseInt(start[1]))];

                    if (range.min === range.max) {
                        slider.setAttribute('disabled', true);
                    } else {
                        slider.removeAttribute('disabled');

                        slider.noUiSlider.destroy();

                        settings.range.min = range.min || 0;
                        settings.range.max = range.max > page.PRICE_MAX ? page.PRICE_MAX : range.max;

                        vals[0] = filter.priceMin || range.min;
                        vals[1] = filter.priceMax || range.max;

                        settings.start = vals;

                        noUiSlider.create(slider, settings);
                        _add_event_listeners();
                    }
                }
            };
        };

        function setupFilter() {
            $.each(['gender', 'children', 'color', 'cat', 'shop', 'sale', 'size'], function (index, value) {
                var $active = widget[value]
                    .children()
                    .removeClass('active')
                    .filter(function () {
                        if ($.isArray(filter[value])) {
                            return $.inArray($(this).data('id').toString(), filter[value]) !== -1;
                        } else {
                            return $(this).data('id').toString() === filter[value];
                        }
                    })
                    .addClass('active');

                    if (value === 'shop' && filter[value].length === 0) {
                        widget[value].children('.all-shops-link').addClass('active');
                    }

                // TODO: Figure out why e.g. Brand is not working like this.
                if (filter[value].length && -1 !== $.inArray(value, ['gender', 'children', 'color', 'cat'])) {
                    widget[value]
                        .closest('.filter-widget')
                        .children('h3')
                        .append(cftb(filter[value], value, str_limit($active.children().first().html(), 10)));
                }
            });

            if (filter.shop.length) {
                widget.shop.show().prev().addClass('active');
            }

            if (filter.search) {
                widget.search.val(filter.search.replace(/\+/g, ' '));
            }
        }

        function _setup_sort_widget() {
            if (filter.sort) {
                widget.sort = $('.filter-sort');

                var sort_val = widget.sort.children('[data-id=' + filter.sort + ']').children().html(),
                    context = widget.sort.closest('.fm-dropdown').children('button');

                context.html(sort_val);
            }
        }

        function clearFilter(except) {
            except = except || [];

            $.each(['gender', 'color', 'cat', 'brand', 'priceMin', 'priceMax', 'sale', 'shop', 'search', 'size'], function (i, x) {
                if (-1 === $.inArray(x, except)) {
                    switch (x) {
                        case 'priceMin':
                            filter[x] = 0;
                            break;
                        case 'priceMax':
                            filter[x] = page.PRICE_MAX;
                            break;
                        case 'shop':
                        case 'size':
                            filter[x] = [];
                            break;

                        default:
                            filter[x] = '';
                            break;
                    }
                }
            });

            // Cosmetics.
            $.each(['gender', 'color', 'cat', 'shop', 'sale', 'size'], function (index, value) {
                if (-1 === $.inArray(value, except) && widget[value]) {
                    widget[value].children().removeClass('active');

                    if (value === 'shop') {
                        widget[value].children('.all-shops-link').addClass('active');
                    }
                }
            });

            if (-1 === $.inArray('search', except) && widget.search) {
                widget.search.val('');
            }

            if (-1 === $.inArray('brand', except) && widget.brand) {
                widget.brand.input.val('');
                widget.brand.list.children().removeClass('active');
                widget.brand.list.closest('.fm-input-dropdown-open').removeClass('fm-input-dropdown-open');
            }

            if (-1 === $.inArray('price', except) && widget.price) {
                widget.price.slider.noUiSlider.set([filter.priceMin, filter.priceMax]);
            }

            if (widget.filter) {
                widget.filter.find('[data-fm-id=filter-tag]').remove();
            }
        }

        function getFilterData() {
            var data = {
                cat: filter.cat,
                gender: filter.gender,
                shop:filter.shop,
            };

            // This FILTERED brand list is used in main search also.
            // Maybe it should not.
            $.getJSON('/api/brands', data, function (brands) {
                var name;

                cache.brands = brands;

                $.each(brands, function (index, brand) {
                    if (brand.slug === filter.brand) {
                        name = brand.name;
                        return false;
                    }
                });

                if (filter.brand) {
                    widget.brand.input.val(name);
                    widget.brand.input
                        .trigger('keyup')
                        .closest('.filter-widget')
                        .children('h3')
                        .append(cftb(filter.brand, 'brand', str_limit(name, 10)));
                }
            });

            // Only used in out-of-filter search.
            $.getJSON('/api/cats', function (cats) {
                cache.cats = cats;
            });

            if($('a[data-type=shop]').length){
                filter.shop = [$('a[data-type=shop]').attr('data-id')]
            }
        }

        /**
         * If the url contains a hash portion this function breaks it down.
         */
        function hashToFilterBinding() {
            var hash = window.location.hash.split('/');

            // This is set to 12, it could be set to 13, but if I set it to anyting other
            // it would make the hash urls out there on the internets break.
            if (hash.length < 12) {
                if (widget.search.val().length > 0) {
                    var bar ={
                        search: widget.search.val()
                    };

                    $.extend(filter, bar);
                }

                return false;
            }

            var foo = {
                gender: hash[1],
                children: hash[2],
                cat: hash[3],
                brand: hash[4],
                priceMin: +hash[5] || '',
                priceMax: +hash[6] || '',
                color: hash[7],
                sale: hash[8],
                perPage: hash[9],
                sort: hash[10],
                shop: hash[12] === '' ? [] : hash[12].replace(/^:|:$/g, '').split(':'),
                search: hash[13] || '',
                size: hash[14] === '' ? [] : hash[14].replace(/^,|,$/g, '').split(','),
            };
            page.num = hash[11];

            $.extend(filter, foo);

            return true;
        }

        function updateHash() {
            window.location.hash = hash();
        }

        function hash() {
            var regex,
                hash = '#/' +
                    filter.gender + '/' +
                    filter.children + '/' +
                    filter.cat + '/' +
                    filter.brand + '/' +
                    filter.priceMin + '/' +
                    filter.priceMax + '/' +
                    filter.color + '/' +
                    filter.sale + '/' +
                    filter.perPage + '/' +
                    filter.sort + '/' +
                    page.num + '/' +
                    filter.shop.join(':') + '/' +
                    filter.search + '/' +
                    filter.size;

            regex = new RegExp('^#\/' + (pathname_filter['gender'] || '') + '\/\/' + (pathname_filter['cat'] || '') + '\/' + (pathname_filter['brand'] || '') + '\/0?\/(' + page.PRICE_MAX + ')?\/\/\/\/\/(0|1)?\/\/\/$');

            return hash.match(regex) ? '/' : hash;
        }

        $(document).on('click', '#sale-btn--deals',function (e) {
            e.stopPropagation()
            filter.sort = 'popular';
            filter.priceMin = 11
            filter.priceMax = 500

            getContent()
        })

        function getContent(append) {
            if (!append) page.num = 1;

            updateHash();

            var data = {
                cat: filter.cat,
                gender: filter.gender,
                children: filter.children,
                brand: filter.brand,
                priceMin: +filter.priceMin,
                priceMax: +filter.priceMax,
                color: filter.color,
                size: JSON.stringify(filter.size),
                sort: filter.sort,
                sale: filter.sale,
                page: page.num,
                show: filter.perPage,
                search: encodeURIComponent(filter.search),
                shop: JSON.stringify(filter.shop)
            };


            // Fade out the items while loading.
            // Might not have this inline since
            // we might want to show a loading
            // icon or something.
            animateOpacity(widget.rowItems.find('.items-content'), 0.5, 200);

            if (page.xhr) page.xhr.abort();
            page.xhr = $.getJSON('/api/items', data)
                .done(function (content) {
                    $('.items-heading-text').addClass('items-heading-text-hide');

                    // Active filtering
                    content.active = [];

                    ++page.newsletterInteractions;

                    if (data.search.length) {
                        var keywords = decodeURIComponent(filter.search).split('+');
                        for (var i = 0; i < keywords.length; i++) {
                            if (keywords[i].length) {
                                content.active.push({ id: i, name: keywords[i], type: 'search' });
                            }
                        }
                    }

                    $.each(['gender', 'brand', 'color', 'cat', 'shop', 'size'], function (i, x) {
                        if (filter[x].length) {
                            content.active.push({ id: content.filter[x].slug, name: content.filter[x].name, type: x });
                        }
                    });

                    if (content.prices.min !== content.prices.max &&
                        ((data.priceMin !== 0 && data.priceMin !== content.prices.min) ||
                            (data.priceMax !== 0 && data.priceMax !== page.PRICE_MAX && data.priceMax !== content.prices.max))
                    ) {
                        content.active.push({ name: widget.price.display.innerHTML, type: 'price' });
                    }

                    if (filter.sale.length) {
                        content.active.push({ name: widget.sale.children().children().html(), type: 'sale' });
                    }

                    // Cache
                    cache.cats = content.cats;
                    cache.brands = content.brands;

                    // Children
                    var childrenSlug = widget.gender.children().last().data('id');
                    widget.children.toggleClass('hidden', data.gender !== childrenSlug);

                    // Brand
                    widget.brand.input.trigger('keyup');

                    // Cats
                    template.cats = template.cats || hbs('filter-cat');
                    widget.cat.html(template.cats(content));

                    // Sizes
                    template.sizes = template.sizes || hbs('filter-size');
                    widget.size.html(template.sizes(content));

                    // Sizes scroll
                    var $size_li = widget.size.find('.active').first();
                    if ($size_li[0]) {
                        widget.size.scrollTop($size_li.position().top + widget.size.scrollTop() - 10);
                    }

                    // Breadcrumb
                    updateBreadcrumb(content.breadcrumb);

                    // Feed
                    widget.rowItems.html(template.items(content));

                    // Influencers
                    influencers.init();

                    // Sorting
                    _setup_sort_widget();

                    // Colors
                    widget.color.children().addClass('inactive');
                    $.each(widget.color.children(), function (i, el) {
                        var $el = $(el);

                        if (-1 !== $.inArray($el.data('id'), content.colors)) {
                            $el.removeClass('inactive');
                        }
                    });

                    // Price
                    widget.price._slider.rebuild(content.prices);

                    // Nums
                    widget.filter.find('.items-num')
                        .contents()
                        .filter(function () {
                            return this.nodeType === Node.TEXT_NODE;
                        })
                        .first()
                        .replaceWith(content.items.total + ' ');

                    animateOpacity(widget.rowItems.find('.items-content'), 1);
                });
        }

        // TODO: Maybe make this into a hbs template.
        function updateBreadcrumb(crumbs) {
            var string = '';
            $.each(crumbs, function (key, value) {
                string += '<a href="' + key + '">' + value + '</a> / ';
            });

            // Remove the last trailing slash in the bredcrumbs
            string = string.slice(0, -3);
            widget.breadcrumb.html(string);
        }

        function animateOpacity(obj, opacity, time) {
            obj.animate({ opacity: opacity }, time ? time : 0);
        }

        // TODO: Make sure we always show the active brand.
        function searchBrandCallback(event) {
            event.preventDefault();

            if (event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 13 || event.keyCode === 27) {
                if(event.keyCode === 13){
                    var $val = widget.brand.input.val();
                    if($val != ''){
                        $('.filter-brand').find('li:first').children('a').trigger('click')
                    }
                    return false;
                }else{
                    return;
                }
            }

            var val = widget.brand.input.val(),
                $container = widget.brand.list.parent().parent(),
                pattern,
                brands;

            if (val.length > 1) {
                pattern = new RegExp('.*' + escape_regex(val) + '.*', 'i');
                brands = [];

                $.each(cache.brands, function (key, value) {
                    if (brands.length > 5) return;

                    if (pattern.test(value.name)) {
                        brands.push({ slug: value.slug, name: value.name });
                        if (value.slug == filter.brand) {
                            brands[brands.length - 1].active = 'active';
                        }
                    }
                });

                $container.addClass('fm-input-dropdown-open');

                template.filterBrands = template.filterBrands || hbs('filter-brands');
                widget.brand.list.html(template.filterBrands({ brands: brands }));
            } else {
                $container.removeClass('fm-input-dropdown-open');
            }
        }

        function mainSearchCallback() {
            var $this = $(this),
                val = $this.val(),
                $current = widget.mainSearch.children('.current').first();

            // Theses two rows make sure that the keyboard
            // on iOS units hide after submitting the search
            document.activeElement.blur();
            $this.blur();
            $this.siblings('ul').hide();

            $this.parent().removeClass('fm-input-dropdown-open');
            $this.val('');

            if (widget.filter) {
                clearFilter(['gender', 'search']);
            }

            if ($current[0]) {
                mainSearchFilterCallback.call($current.children('a').get(0));
                return;
            }

            // Squeeze spaces
            filter.search = val.trim().replace(/\s+/g, ' ').split(' ').join('+');

            ga('send', 'event', 'search', 'phrase', val);

            if (widget.filter) {
                //clearFilter(['gender', 'search']);

                getContent();
                sendToTop(true);
            } else {
                window.location = '//' + window.location.host + '/' + hash();
            }
        }

        function searchMainCallback(event) {
            event.preventDefault();
            return;

            // Here is the search logic.
            var val = widget.search.val(),
                list = widget.search.siblings('ul');

            if (event.keyCode === 13) {
                mainSearchCallback.call(widget.search);
                return;
            }

            if (event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 27) {
                return;
            }

            // If the input is more than one character, go ahead and show searched brand.
            // TODO: Enfore the fact stated in the line above.
            if (val.length > 0) {
                list.show();
                list.parent().addClass('fm-input-dropdown-open');

                $('.banner-icon-close').on('off');
                $('.banner-icon-close').on('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();

                    widget.search.val('');
                    list.hide();
                    list.parent().removeClass('fm-input-dropdown-open');
                });

                var pattern = new RegExp('.*' + escape_regex(val) + '.*', 'i'),
                    show = [];

                // use: pattern, cache, filter
                $.each(['brands', 'cats'], function (index, col) {
                    $.each(cache[col], function (key, value) {
                        if (show.length > 10) return;
                        var type = col.substr(0, col.length - 1);

                        if (pattern.test(value.name)) {
                            var match = {
                                slug: value.slug,
                                name: value.name,
                                type: type,
                                typeName: _filter_name[type]
                            };

                            if (filter[type] === value.slug) {
                                match.active = ' class=active';
                            }

                            show.push(match);
                        }
                    });
                });

                /*
                show.unshift({
                    slug: val,
                    name: val,
                    type: 'search',
                    typeName: _filter_name['search']
                });
                */

                template.filterMain = template.filterMain || hbs('main-search');
                list.html(template.filterMain({ data: show }));
            } else {
                list.hide();
                list.parent().removeClass('fm-input-dropdown-open');

                $('.banner-icon-close').off('click');
            }
        }

        function mainSearchFilterCallback(event) {
            if (event && event.hasOwnProperty('preventDefault')) {
                event.preventDefault();
            }

            var $this = $(this).parent(),
                val = $this.children().contents().first().text(),
                type = $this.data('type'),
                id = $this.data('id'),
                isActive = $this.hasClass('active'),
                $ul = $('.filter-' + type);

            if (type == 'brand') {
                widget.brand.input.val(val);
                widget.brand.input.trigger('keyup');
            }

            ga('send', 'event', 'search', type, val);

            $ul.children('[data-id=' + id + ']')
                .toggleClass('active')
                .siblings()
                .removeClass('active');

            filter[type] = isActive ? '' : id;


            toggleFilterHeadingTag(type, id, val);

            getContent();
            $this.parent().hide()
                .parent().removeClass('fm-input-dropdown-open');
        }

        function addFilterEventListeners() {
            widget.brand.input.on('keyup', searchBrandCallback);

            // Filter listeners.
            widget.color.on('click', 'a', 'color', filterListCallback);
            widget.size.on('click', 'a', 'size', filterMultiListCallback);
            widget.cat.on('click', 'a', 'cat', filterListCallback);
            widget.gender.on('click', 'a', 'gender', filterListCallback);
            widget.children.on('click', 'a', 'children', filterListCallback);
            widget.rowItems.on('click', '.filter-per-page a', 'perPage', filterListCallback);
            widget.brand.list.on('click', 'a', 'brand', filterListCallback);
            widget.shop.on('click', 'a', 'shop', filterMultiListCallback);
            widget.sale.on('click', 'a', 'sale', filterListCallback);

            // Main search filtering
            widget.mainSearch.on('click', 'a', mainSearchFilterCallback);

            $(document).on('click', '[data-fm-id=filter-tag]', function (event) {
                event.preventDefault();
                event.stopPropagation();

                var $this = $(this),
                    id = $this.data('id'),
                    type = $this.data('type'),
                    tags = filter.search.split('+');

                switch (type) {
                    case 'search':
                        widget.search.val(filter.search);
                        tags.splice(id, 1);
                        filter.search = tags.join('+');
                        break;

                    case 'price':
                        widget.price.slider.noUiSlider.set([filter.priceMin, filter.priceMax]);
                        filter.priceMin = 0;
                        filter.priceMax = page.PRICE_MAX;
                        break;

                    case 'clear':
                        $this.parent().html('');
                        clearFilter();
                        break;

                    case 'brand':
                        widget[type].input.val('');
                        widget[type].list.html();
                        filter[type] = '';
                        break;

                    case 'shop':
                        widget[type].children().removeClass('active');
                        widget[type].children('.all-shops-link').addClass('active');
                        filter[type] = [];
                        break;

                    case 'cat':
                    case 'children':
                    case 'color':
                    case 'gender':
                    case 'sale':
                        widget[type].children().removeClass('active');
                        filter[type] = '';
                        break;

                    case 'size':
                        widget[type].children().removeClass('active');
                        filter[type] = [];
                        break;
                    default:
                        break;
                }

                $this.remove();
                $('[data-fm-id=filter-tag][data-type=' + type + ']').remove();

                getContent();
            });

            // Pagination
            widget.rowItems.on('click', '.pagination a', paginationCallback);
            widget.rowItems.on('click', '.pagination .send-to-top', sendToTopCallback);
            widget.filter.on('click', 'li, [data-fm-id=filter-reset]', sendToTopCallback);

            widget.rowItems.on('click', '.filter-sort a', 'sort', filterSortCallback);

            $(document).on('click', '[data-fm-id=filter-reset]', function () {
                ga('send', 'event', 'filter', 'clear');
                clearFilter();
                getContent();
            });
        }

        function sendToTopCallback(event) {
            event.preventDefault();

            sendToTop($(this).hasClass('send-to-top'));
        }

        function sendToTop(override) {
            if (override || mediaWidth.active !== 'mobile') {
                $('body, html').animate({ scrollTop: 0 }, { duration: 400, easing: 'swing' });
            }
        }

        function sendToAnchor(event) {
            event.preventDefault();
            var anchor = $(this).attr('href').split('#');

            if (page.sidebarSlideMenu) {
                page.sidebarSlideMenu.close();
            }

            anchor = anchor[anchor.length - 1];
            anchor = $('[name=' + anchor + ']').offset().top;
            $('body, html').animate({ scrollTop: anchor - 100 }, { duration: 400, easing: 'swing' });
        }

        function paginationCallback(event) {
            event.preventDefault();

            page.num = parseInt($(this).parent().data('id'), 10);
            getContent(true);
        }

        function favItem(event) {
            event.preventDefault();
            var cookie_work = true;

            if($(this).attr('data-access'))cookie_work = false;

            var $item = $(this).parent().parent(),
                id = $item.data('id') || $(this).closest('.item-meta').data('id'),
                faved = $item.hasClass('faved'),
                $itemInFeed = $item.hasClass('item') ? $item : $('.item[data-id=' + id + ']'),
                $fav = $itemInFeed.find('[data-fm-id="item-fav"]'),
                num;

            if (in_path('favourites')) {
                $itemInFeed.css({ opacity: faved ? .5 : 1 });
            }

            $fav.html(parseInt($fav.html()) + (faved ? -1 : 1));

            $item.toggleClass('faved', !faved);
            if (!$item.is($itemInFeed)) {
                $itemInFeed.toggleClass('faved', !faved);
            }

            if (faved) {
                if(cookie_work){
                    num = Cookie.eraseFromArray('favs', id);
                    ga('send', 'event', 'favourite', 'off');
                    $('.favs-count').text(JSON.parse(Cookie.get('favs')).length)
                }
            } else {
                if(cookie_work){
                    num = Cookie.addToArray('favs', id);
                    ga('send', 'event', 'favourite', 'on');
                    $('.favs-count').text(JSON.parse(Cookie.get('favs')).length)
                }

                maybeShowNewsletterModal(1000)

                $.ajax({
                    type: 'PUT',
                    url: '/api/items/' + id,
                    data: {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        favourite: null
                    }
                });
            }

            ++page.newsletterInteractions;
            updateBannerCounter('fav', num);
            if($('.favs-count').text()>0){
                $('.check_general_heart_icon').addClass('item faved')
            }else{
                $('.check_general_heart_icon').removeClass('item faved')
            }
        }

        function updateBannerCounter(type, num) {
            widget[type + '_banner']
                .toggleClass('active', !!num)
                .html(num);
        }

        function showItemModal(event) {
            if($(this).hasClass('img-item')){
                event.stopPropagation()
            }
            var id,
                source,
                $this;

            // This is a lazy way of giving feedback to the user that they are
            // showing another show when clicking on related products.
            Modal.close();

            if (typeof event === 'object') {
                event.preventDefault();

                $this = $(this);
                id = $this.closest('.item').data('id');
                source = $this.data('source');

                // IE SUX.
                // If anyone know why this needs to exist and this has no
                // parentNode, please tell me why that is, if you know.
                if (!id) id = $this.data('id');
            } else {
                id = event;
            }

            id = parseInt(id, 10);

            // Start fetching data.
            $.getJSON('/api/items/' + id)
                .done(Modal.showAsync(function (content) {
                    template.item_modal = template.item_modal || hbs('item-modal');
                    Modal.html(template.item_modal(content));
                    ++page.newsletterInteractions;

                    // Log that the user looked at this shoe.
                    var num = Cookie.addToArray('latest', id, 30);
                    updateBannerCounter('latest', num);


                    ga('send', 'event', 'info', 'shop', content.item.shop.name);
                    ga('send', 'event', 'info', 'brand', content.item.brand.name);
                    ga('send', 'event', 'info', 'category', content.item.cat.name);
                    if (0 !== content.item.price_ordinary) {
                        ga('send', 'event', 'info', 'sale', content.item.shop.name);
                    }
                    if (source) {
                        ga('send', 'event', 'info', 'source', source);
                    }
                }, 'modal-wrapper-item'))
                .fail(Modal.showAsync(function () {
                    Modal.html('<div class="center">' +
                        '<h1>This shoe could not be found</h1>' +
                        '<img src="/gfx/404.png">' +
                        '<p>Please try clicking on another item!</p>' +
                        '</div>');
                }, 'modal-wrapper-small'))
                .always(function () {
                    window.location.hash = '#/item/' + id;
                });
        }

        function filterSortCallback(event) {
            event.preventDefault();

            var $this = $(this).parent(),
                $button = $this.closest('.fm-dropdown').find('button'),
                dropdown = $this.closest('.fm-dropdown');

            filter.sort = $this.data('id');
            getContent();

            _setup_sort_widget();
            if(dropdown.hasClass('fm-dropdown-open')){
                $button.trigger('click');
            }
        }

        function toggleFilterHeadingTag(type, id, value, toggleTrue) {
            var $h3 = $('.filter-' + type).closest('.filter-widget').children('h3'),
                $tag = $h3.children('a');

            if (typeof toggleTrue !== 'undefined' && !toggleTrue && $tag[0]) {
                $tag.remove();
            } else {
                if (type !== 'sale') {
                    if ($tag[0]) {
                        $tag.data('id', id);
                    } else {
                        $tag = cftb(id, type).appendTo($h3);
                    }
                }

                switch (type) {
                    case 'sale':
                        break;
                    case 'color':
                        $tag.html('');
                        break;

                    default:
                        $tag.html(str_limit(value, 10));
                        break;
                }

                $tag.attr('data-id', id);
            }
        }

        function filterListCallback(event) {
            event.preventDefault();

            var $this = $(this).parent(),
                val,
                $h3 = $this.closest('.filter-widget').children('h3'),
                $tag = $h3.children('a');

            if ($this.hasClass('inactive')) {
                return false;
            }

            if ($this.hasClass('active')) {
                $this.removeClass('active');
                filter[event.data] = '';

                toggleFilterHeadingTag(event.data, '', '', false);
            } else {
                if (!$this.parent().hasClass('filter-cat')) {
                    $this.addClass('active').siblings().removeClass('active');
                }
                val = $this.data('id').toString();

                toggleFilterHeadingTag(event.data, val, $this.children().html());

                filter[event.data] = val;

                ga('send', 'event', 'filter', event.data, val);
            }

            getContent();
        }

        function cftb(val, type, html) {
            return $('<a href="#" data-fm-id="filter-tag" data-id="' + val + '" data-type="' + type + '" class="btn btn-small btn-white btn-tag">' + (html || '') + '</a>');
        }

        function filterMultiListCallback(event) {
            event.preventDefault();

            var $this = $(this).parent();

            if ($this.hasClass('inactive')) {
                return false;
            }

            if ($this.hasClass('active') && !$this.hasClass('all-shops-link')) {
                $this.removeClass('active');

                var index = $.inArray($this.data('id').toString(), filter[event.data]);
                if (index !== -1) {
                    filter[event.data].splice(index, 1);
                }
            } else {
                $this.addClass('active');
                if (event.data === 'shop') {
                    if ($this.hasClass('all-shops-link')) {
                        filter[event.data].length = 0;
                        $this.siblings().removeClass('active');
                    } else {
                        filter[event.data].push($this.data('id').toString());
                        $this.siblings('.all-shops-link').removeClass('active');
                    }
                } else {
                    filter[event.data].push($this.data('id').toString());
                }
            }

            getContent();
        }

        function scrollCallback() {
            var $this = $(this),
                $skip = $('.banner-campaign, .banner-info'),
                main_height = $('.banner-main').outerHeight(),
                scroll = $(window).scrollTop(),
                additional,
                sum;

            if (scroll > 1000) {
                widget.up.fadeIn(200);
            } else {
                widget.up.fadeOut(200);
            }

            sum = $skip.toArray().reduce(function (a, b) {
                return a + $(b).outerHeight();
            }, 0);

            additional = mediaWidth.active === 'mobile' ? $('#main-nav').outerHeight() : 0;

            if (scroll > sum + additional) {
                $(document.body).addClass('banner-loose')
                    .css({ 'margin-top': main_height + additional });
            } else {
                $(document.body).removeClass('banner-loose')
                    .css({ 'margin-top': 0 });
            }
        }

        function hasSvg() {
            return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
        }

        function fixSvg() {
            if (!hasSvg()) {
                var $logo = $('.logo'),
                    original = $logo.attr('src');

                $logo.attr('src', original.replace(/\.svg/, '.png'));
                $('html').addClass('no-svg');
            }
        }

        $('[data-fm-keyboard-interactive=input]').on('keyup', function (event) {
            event.preventDefault();

            if (!(event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 13 || event.keyCode === 27)) {
                return;
            }

            var $this = $(this),
                $context = $this.parent().parent().find('[data-fm-keyboard-interactive=context]:not(.hidden)'),
                $current = $context.find('.current'),
                $temp;

            if (event.keyCode === 40) {
                if (!$current[0]) {
                    $current = $context.find('li:first');
                } else {
                    $temp = $current.next();
                    $current.removeClass('current');

                    if (!$temp[0]) $temp = $context.find('li:first');
                    $current = $temp;
                    $temp = null;
                }

                $current.addClass('current');
            } else if (event.keyCode === 38) {
                if (!$current[0]) {
                    $current = $context.find('li:last');
                } else {
                    $temp = $current.prev();
                    $current.removeClass('current');

                    if (!$temp[0]) $temp = $context.find('li:last');
                    $current = $temp;
                    $temp = null;
                }

                $current.addClass('current');
            } else if (event.keyCode === 13) {
                if ($current[0]) {
                    if ($this.data('fm-id') === 'filter-brand-input') {
                        $current.children().trigger('click');
                    } else {
                        window.location = $current.children().first().attr('href');
                    }
                }
            } else if (event.keyCode === 27) {
                $context.closest('.fm-dropdown').find('.btn-dropdown').trigger('click');
            }
        });

        $(document).on('click', '.btn-dropdown', function (e) {
            e.preventDefault();

            var $this = $(this).parent('.fm-dropdown'),
                $toggles = $this.find('.fm-dropdown-content');

            if ($this.hasClass('fm-dropdown-open')) {
                $toggles.hide();
                $this.removeClass('fm-dropdown-open');
                $this.find('.current').removeClass('current');
            } else {
                $toggles.show();
                $this.addClass('fm-dropdown-open');

                $this.find('input').focus();
            }
        });

        // BOOT, BOOOT, BOOOOT!
        fixSvg();
        bootstrap();

        /*
        if (in_path(['locals', 'nearby', 'cp'])) {
            LocalsBooter();
        }
        */

    }(jQuery, Budskap, Cookie, Handlebars, Modal, SlideMenu);

})(window);
