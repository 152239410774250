(function (window, $, undefined) {

    function escape_regex(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    function hbsc(str) {
        return Handlebars.compile(str);
    }

    function hbs(template) {
        var $template = $('#template-' + template);
        return $template.length ? hbsc($template.html()) : function () { };
    }

    /**
     * Slide menu JavaScript.
     */
    var SlideMenu = function (selector, callback) {
        var $backdrop = $('.slide-menu-backdrop'),
            $menu = $(selector || '.slide-menu'),
            $canvas = $('html, body'),
            level = 0,
            cache,
            out_tmpl;

        callback = callback || function () { };

        $backdrop.on('click', _close_event);
        $menu.on('click', '.slide-menu-header', _close_event);
        $(window.document).on('keyup', _close_event);
        $menu.on('scroll', _scroll_event);

        // $menu.on('click', 'h3', function (event) {
        //     event.preventDefault();

        //     // TODO: Figure out why I need this.
        //     if (event.target !== this) {
        //         return;
        //     }

        //     //$(this).toggleClass('filter-show');
        // });

        // $menu.on('click', 'ul a', function (event) {
        //     var $this = $(this),
        //         $h3 = $this.closest('.filter-widget').children('h3');

        //     // Had to disable this because of problems with classes on .slide-menu /SP
        //     //$h3.removeClass('filter-show');
        // });

        $menu.on('click', '[data-filter-goto]', function (event) {
            event.preventDefault();

            var $this = $(this),
                context_data = $this.closest('ul').data('filter-context') || {},
                what = $this.data('filter-goto'),
                id = $this.data('id');

            context_data[what] = id;
            callback(context_data);
        });

        $menu.on('keyup', '[data-fm-id=slide-menu-brand-input]', function (event) {
            event.preventDefault();

            var $this = $(this),
                val = $this.val(),
                $list = $this.siblings('ul'),
                pattern,
                result;

            if (val.length > 1) {
                pattern = new RegExp('.*' + escape_regex(val) + '.*', 'i');

                result = cache.brand.data.filter(function (brand) {
                    return pattern.test(brand.name);
                });

                $list.children().each(function (i, el) {
                    var $el = $(el),
                        show = result.some(function (val, i) {
                            return $el.data('id') === val.slug;
                        });

                    $el.toggle(show);
                });
            } else {
                $list.children().show();
            }
        });

        $menu.on('click', '[data-slide-to]', function (event) {
            event.preventDefault();

            var $this = $(this),
                from = $this.html(),
                $level = $this.closest('.slide-menu-level'),
                $levelAux = $level.siblings('.slide-menu-level'),
                to = $this.data('slide-to'),
                id = $this.data('id'),
                cache_key,
                $out;

            if (-1 === to) {
                $level.children('.slide-menu-template-output').html('');
            } else {
                $levelAux.find('.slide-menu-back a').html(from);
                $out = $levelAux.children('.slide-menu-template-output');
                out_tmpl = out_tmpl || hbs('slide-menu-list');

                cache_key = to === 'gender' ? to + '.' + id : to;

                if (cache[cache_key]) {
                    $out.html(out_tmpl(cache[cache_key]));
                } else {
                    switch (to) {
                        case 'brand':
                            $.getJSON('/api/brands', function (content) {
                                var input = hbs('slide-menu-brand-input');
                                cache[cache_key] = { data: content, filter: to };
                                $out.html(input() + out_tmpl(cache[cache_key]));
                            });
                            break;
                        case 'cat':
                            $.getJSON('/api/cats', function (content) {
                                cache[cache_key] = { data: content, filter: to };
                                $out.html(out_tmpl(cache[cache_key]));
                            });
                            break;

                        case 'gender':
                            $.getJSON('/api/cats', { gender: id }, function (content) {
                                cache[cache_key] = { data: content, filter: 'cat', what: JSON.stringify({ gender: id }) };
                                $out.html(out_tmpl(cache[cache_key]));
                            });
                            break;

                        default:
                            $out.html('<p>Error</p>');
                            break;
                    }
                }
            }

            $levelAux.show();
            $level.hide();
        });

        cache = {};

        function _scroll_event(event) {
            var scrollTop = $menu.scrollTop(),
                before = $('.slide-menu-header').outerHeight(),
                $fixed = $('.slide-menu-back'),
                fixedHeight = $fixed.outerHeight();

            if (scrollTop > before) {
                $fixed
                    .addClass('slide-menu-fixed')
                    .closest('.slide-menu-level')
                    .css({ 'padding-top': fixedHeight });
            } else {
                $fixed
                    .removeClass('slide-menu-fixed')
                    .closest('.slide-menu-level')
                    .css({ 'padding-top': 0 });
            }
        }

        function _close_event(event) {
            if ((event.type === 'click' && event.target !== this) ||
                (event.type === 'keyup' && event.keyCode !== 27)) return;

            event.preventDefault();

            close();
        }

        function show() {
            $canvas.addClass('slide-menu-open');
            $menu.addClass('slide-menu-active');

            return this;
        }

        function close() {
            $canvas.removeClass('slide-menu-open');
            $menu.removeClass('slide-menu-active');

            return this;
        }

        return {
            /**
             *
             */
            show: show,
            close: close,

            _$menu: $menu
        };
    };

    window.SlideMenu = SlideMenu;

})(window, jQuery);

