$(document).ready(function () {

    var for_winning
    var type;
    var id;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

                /* login or registration*/

    /* Sale page buttons click */
    $(document).on('click', '.sale-btn', function () {
        var ty_data = $(this).attr('data-id')
        $('.filter-sort li[data-id='+ty_data+'] a').trigger('click');
    })

                /* add sizes */

    $('.o-shoe-sizes li').click(function () {
        $(this).find('input:checkbox').prop('checked', !$(this).hasClass('added-size'));
    })

    $(document).on('click','.add-rm', function(event){
        event.preventDefault()
        var $this = $(this);
        type = $(this).data('btn-func') ? $(this).attr('data-btn-func') : false;
        var category = $this.attr('data-rm');
        var num = $(this).data('id') ? $this.attr('data-id') : $this.closest('.onesixth').attr('data-id')
        id = num
        $.ajax({
            url:'/profile/data',
            type:'post',
            data:{category:category,num:num},
            dataType:'json',
            success:function (res) {
                if (res) {
                    if(category=='priceAlertItems'){
                        $this.parent().toggleClass('alerted')
                    }

                    $this.toggleClass('active')
                    if (category != 'favourites' && category!='viewedItems' && $this.closest('.row').hasClass('not-remove')) {
                        $this.closest('.onesixth').fadeTo( "fast", $this.closest('.onesixth').css("opacity") == "1" ? "0.5" : "1")
                    }
                    if(category != 'notify'){
                        $.each(res,function (index,value) {
                            if(index=='favs'){
                                if(value>0){
                                    $('.check_general_heart_icon').addClass('item faved')
                                }else{
                                    $('.check_general_heart_icon').removeClass('item faved')
                                }
                            }
                            $('.'+index+'-count').text(value)
                            $('.'+index+'-heading').children('span').text(value)
                        })
                    }
                    if(category=='removeViews'){
                        $this.closest('.row-items').find('.item').remove()
                        $('.views-heading').remove()
                        $this.remove();
                    }
                }
            }
        })
    })

    $('.remove-account').click(function () {
        $.ajax({
            url:'/profile/remove',
            data:{},
            type:'post',
            success:function (res) {
                if(res){
                    location.replace('/authentication/sign-up')
                }else{
                    alert('Server Error')
                }
            }

        })
    })

    $(document).on('click','input[name=account]', function () {
        var open = $(this).val();
        for_winning = open;
        if(open == 1){
            $('.for-modal-display').css({'display' : 'none'})
            $('.reg-or-log').removeClass('send-login-data')
        }else{
            $('.for-modal-display').css({'display' : 'block'})
            $('.reg-or-log').addClass('send-login-data')
        }
    })

    $(document).on('click', '.reg-or-log', function (event) {
        if($(this).hasClass('send-login-data') && !$(this).hasClass('m-btn-login__facebook')){
            event.preventDefault()
        }else{
            localStorage.setItem('log-email',$(this).closest('.modal').find('input[type=email]').val())
            location.replace($(this).attr('data-href'));
        }
        setItemCookie(type,id)
        if($(this).hasClass('send-login-data')) {
            var $form = $(this).closest('.fm-modal').find('form') ;
            if(!$form.length) $form = $(this).closest('.modal').find('form');
            if(!$form.length) $form = $(this).closest('form');
            var $email = $form.find('input[name=email]')
            var $pass = $form.find('input[name=password]')
            $.ajax({
                url:'/popuplogin',
                type:'post',
                data:{email:$email.val(),password:$pass.val()},
                success:function (res) {
                    switch (res){
                        case '5':
                            ga('send', 'event', 'account', 'login');
                            location.href = "/profile";
                            break;
                        case 'email':
                            $email.parent().addClass('has-error')
                            $email.val('')
                            $pass.val('')
                            break;
                        case 'password':
                            $pass.parent().addClass('has-error')
                            $email.parent().removeClass('has-error')
                            $pass.val('')
                            break;
                    }
                }
            })
        }
    })

})

function fav_count() {
    if(Cookie.get('favs')){
        $('.favs-count').text(JSON.parse(Cookie.get('favs')).length)
    }
}

function setItemCookie(name,id) {
    var now = new Date();
    var time = now.getTime();
    time += 3600 * 500;
    now.setTime(time);
    document.cookie =
        name + '=' + id +
        '; expires=' + now.toUTCString() +
        '; path=/';
}