/**
 * Created by CodeRiders-May on 21.08.2017.
 */

$(window).on("load, resize", function() {
    var viewportWidth = $(window).width();
    if (viewportWidth <=829) {
        $("#filter-container .filter-widget h3").addClass("widget-close").removeClass('widget-open filter-show');
        $("#filter-container .filter-widget .filter-widget-body").removeClass('min-marg-body widget-body-show');
    }else{
        $("#filter-container .filter-widget h3").addClass("widget-open filter-show").removeClass('widget-close');
        $("#filter-container .filter-widget .filter-widget-body").addClass('min-marg-body widget-body-show');
    }
});

$(document).ready(function () {
    //checkSize()

    if (localStorage.getItem('row-count') == 3) {
        $('.onesixth').addClass('onethreeth')
        $('.influencer').addClass('influencer-feed__large');
        $('.items-row-count-btn').removeClass('checked-count')
        $('.row-3').addClass('checked-count')
    }
})

$(document).on('click', '.filter-widget-heading', function () {
    var body = $(this).parent().find('.filter-widget-body')
    body.toggleClass('widget-body-show');
    var scroll = body.children('.for-scroll').height()
    if (scroll >= 255) {
        body.css({ 'overflow-y': 'scroll' })
    }
    $(this).toggleClass('widget-open widget-close');
    $(this).toggleClass('filter-show');
})


function in_array(size, array) {
    var res = false;
    array.forEach(function (value, index) {
        if (value == size) {
            console.log('tet')
            res = index;
        }
    });
    return res
}

function checkSize() {
    if (localStorage.getItem('sizes')) {
        var sizes = JSON.parse(localStorage.getItem('sizes'));
        sizes.forEach(function (value) {
            $('.filter-size li[data-id=' + value + '] a').addClass('active')
        })
    }
}

$(document).on('click', '.items-row-count-btn', function () {
    var row_count = $(this).attr('data-count')
    $('.items-row-count-btn').removeClass('checked-count')
    $(this).addClass('checked-count')
    if (row_count == 3) {
        $('.onesixth').addClass('onethreeth')
        $('.influencer').addClass('influencer-feed__large');
        localStorage.setItem('row-count', 3)
    } else {
        $('.onesixth').removeClass('onethreeth')
        $('.influencer').removeClass('influencer-feed__large');
        localStorage.removeItem('row-count')
    }
})

$(document).on('click', '.fm-ul-nav li a', function () {
    if ($(this).parent().parent().hasClass('filter-cat')) {
        $(this).parent().parent().children('li').removeClass('active')
        $(this).parent().addClass('active');
    }
})